import React, { createContext, useContext, useState, useCallback } from 'react';
import Toast from './Toast'; // Import your Toast component

const ToastContext = createContext(null);

export function ToastProvider({ children }) {
    const [toasts, setToasts] = useState([]);

    const showToast = useCallback((title, description, duration = 5000) => {
        const id = new Date().getTime(); // Unique ID for each toast
        setToasts((prev) => [...prev, { id, title, description }]);

        // Automatically remove the toast after the duration
        const timeoutId = setTimeout(() => {
            setToasts((prev) => prev.filter((toast) => toast.id !== id));
        }, duration);

        // Clean up timeout on component unmount or re-render
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <div className="toast-container">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        title={toast.title}
                        onClose={() => setToasts((prev) => prev.filter(t => t.id !== toast.id))}
                    />
                ))}
            </div>
        </ToastContext.Provider>
    );
}

// Custom hook to use the toast functions
export function useCustomToast() {
    return useContext(ToastContext);
}
