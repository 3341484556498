import React from 'react';

const Loading = (props) => {	
	return(	
   	<div className="loading-area">
		<div className="loading-box"></div>
		<div className="loading-pic">
			<div className="cssload-loader"></div>
		</div>
	</div>
    )	
}
export default Loading;