import React from 'react';
import './toast.css'; // Create a CSS file for styling the toasts

const Toast = ({ title, description, onClose }) => {
    return (
        <div className="toast">
            <div className="toast-header">
                <strong>{title}</strong>
                <button className="toast-close" onClick={onClose}>
                    &times;
                </button>
            </div>
            <div className="toast-body">
                {description}
            </div>
        </div>
    );
};

export default Toast;
