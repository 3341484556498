import React, { Suspense, lazy, useEffect  } from 'react'
import { Route, Routes } from 'react-router-dom'

import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome/css/font-awesome.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/slick.css';
import './assets/css/slick-theme.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/magnific-popup.min.css';
import './assets/css/loader.min.css';
import './assets/css/style.css';
import './assets/css/flaticon.min.css';

import Loading from './common/loading/Loading';
import { ToastProvider } from './common/toast';
const HomeLayout = lazy(() => import('./layout/HomeLayout'))
const AboutLayout = lazy(() => import('./layout/AboutLayout'))
const ServicesLayout = lazy(() => import('./layout/ServicesLayout'))
const GalleryLayout = lazy(() => import('./layout/GalleryLayout'))
const PagesLayout = lazy(() => import('./layout/PagesLayout'))
const ShopLayout = lazy(() => import('./layout/ShopLayout'))
const BlogLayout = lazy(() => import('./layout/BlogLayout'))
const NotFound = lazy(() => import('./pages/NotFound'))


function App() {
	useEffect(() => {
 		 window.scrollTo(0, 0)
	}, [])
	
  return (
		  <>
			<div className="page-wraper">
				<ToastProvider>
				<Suspense fallback={<Loading />}>								   	  
					<Routes>
						{/*Home Routes*/}
						<Route index element={<HomeLayout />} />
						<Route exact path="/" element={<HomeLayout />} />

						{/*About Us Routes*/}
						<Route exact path="/about-us" element={<AboutLayout />} />

						{/*Services Routes*/}
						<Route exact path="/our-services/" element={<ServicesLayout />} />
						<Route exact path="/services/:title" element={<ServicesLayout />} />

						{/*Gallery Routes*/}
						<Route exact path="/gallery" element={<GalleryLayout />} />
						<Route exact path="/portfolio/:title" element={<GalleryLayout />} />
						
						{/*Shop Routes*/}
						<Route exact path="/products" element={<ShopLayout />} />
						<Route exact path="/product-detail/:productId" element={<ShopLayout />} />
						<Route exact path="/shopping-cart/:type" element={<ShopLayout />} />
						<Route exact path="/checkout" element={<ShopLayout />} />

						{/*Other Pages Routes*/}
						<Route exact path="/contact-us" element={<PagesLayout />} />
						<Route exact path="/faq" element={<PagesLayout />} />

						{/*blog and items*/}
						<Route exact path="/blog-page" element={<BlogLayout />} />
						<Route path="/blog-item/:id" element={<BlogLayout />} />

						{/*not found*/}				
						<Route path="*" element={<NotFound/>} />

					</Routes>					
				</Suspense>
				</ToastProvider>
			</div>						
	</>
  );
}
export default App;